import React, { Component, useEffect, useState} from "react";
import Chart from "react-apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../utils/AssetsHelpers";
import formatter from '../../utils/CurrencyFormat';


export function ChartWidget1({ className, symbolShape, baseColor, chart_data }) {
  let layoutProps = {
    colorsGrayGray200: "#ECF0F3",
    colorsGrayGray300: "#E5EAEE",
    colorsGrayGray500: "#B5B5C3",
    colorsThemeBaseSuccess: "#1BC5BD",
    colorsThemeLightSuccess: "#C9F7F5",
    fontFamily: "Poppins",
  }
  const [options, setOptions] = useState({
    chart: {
      // id: "basic-bar",
      type: "area",
      height: 150,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess],
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      }
    },
    tooltip: {
      y: {
        formatter: (amount) => formatter.format(amount),
        title: {
            formatter: (seriesName) => seriesName,
        },
      }
    }
  })
  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }
  ])
  useEffect(() => {
    if(chart_data.hasOwnProperty("data")){
      setSeries([{name:'Monto', data: chart_data.data.amounts}])
      setOptions({...options, xaxis:{ categories: chart_data.data.labels }})
    }
  },[chart_data])
  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <span className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}>
            <span className="symbol-label">
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </span>
          <div className="d-flex flex-column text-right">
            {/* <span className="text-dark-75 font-weight-bolder font-size-h3">
              750$
            </span> */}
            <span className="text-muted font-weight-bold">
              Ventas por Día
            </span>
          </div>
        </div>
        <div className="card-rounded-bottom">
          <Chart
            options={options}
            series={series}
            type="area"
            height="150"
            width="100%"
          />
        </div>
      </div>
    </div>
  )
}
