import axios from 'axios';
import { actions as generalActions } from './generalActions';
import { useSelector } from 'react-redux'

export const actions = {
    fetch: (params) => async (dispatch) => {
        dispatch(generalActions.isFetching());
        await axios.get('/api/notifications/', { params: { limit: params.limit, offset: params.offset ? params.offset : 0 } })
            .then(({ data }) => {
                dispatch(generalActions.setProperty({ notifications: { data: data.results, totalElements: data.count } }))
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                dispatch(generalActions.isFetchingEnd());
            });
    },
    fetchResumeNotifications: () => async (dispatch) => {
        dispatch(generalActions.isFetching());

        let unread = []
        let read = []

        await axios.get('/api/notifications/', { params: { filter: 'unread', limit: 10 } })
            .then(({ data }) => {

                data.results.forEach((element) => {
                    unread.push(element)
                })
            })
            .catch((error) => {
                console.log(error);
            });

        await axios.get('/api/notifications/', { params: { filter: 'read', limit: 10 } })
            .then(({ data }) => {

                data.results.forEach((element) => {
                    read.push(element)
                })
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(generalActions.setProperty({ resumeNotifications: { read: read, unread: unread } }))
        dispatch(generalActions.isFetchingEnd());
    },
    setReadNotification: (params) => async (dispatch) => {
        dispatch(generalActions.isFetching());

        await axios.put('/api/notifications/'+params+'/', { status: 'read' })
            .then(({ data }) => {

            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(generalActions.isFetchingEnd());
    },
    setUnreadNotification: (params) => async (dispatch) => {
        dispatch(generalActions.isFetching());

        await axios.put('/api/notifications/'+params+'/', { status: 'unread' })
            .then(({ data }) => {

            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(generalActions.isFetchingEnd());
    }
}