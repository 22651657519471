import { persistReducer } from "redux-persist";

import { actionTypes } from "./dashboardRedux";

const initialAuthState = {
  chart_orders: {},
  chart_categories: {},
  resume_dashboard: {
    meli: {
      marketplace_total_day: 0 ,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0,
      most_sell_category: ""
    },
    dafiti: {
      marketplace_total_day: 0 ,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0
    },
    linio: {
      marketplace_total_day: 0 ,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0
    }
  },
};

const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.SetProperty:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.IsFetching:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.IsFetchingEnd:
      return {
        ...state,
        isFetching: false
      }
    case actionTypes.IsSubmit:
      return {
        ...state,
        isSubmit: true
      }
    case actionTypes.IsSubmitEnd:
      return {
        ...state,
        isSubmit: false
      }
    default: return state;
  }
};
export default reducer;