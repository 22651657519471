import React, {useEffect} from "react";
import { connect } from 'react-redux';
import {
  StatsWidget11,
  StatsWidget12,
  ChartWidget1,
  ChartWidget2
} from "./widgets";


import { actions as dashboardActions } from "./_redux/actions/chartActions";
// import { actions as profileActions } from "../../modules/Auth/_redux/actions/profilesActions";
import { actions as meliProfileActions } from "../../modules/Marketplaces/_redux/actions/mercadolibreActions/profilesActions"
import { actions as dafitiProfileActions } from "../../modules/Marketplaces/_redux/actions/dafitiActions/profilesActions"
import CardDeckGroup from "./widgets/CardDeckGroup";
import SalesOverview from "./widgets/SalesOverview";

const Dashboard = ({ chart_orders, chart_categories, resume_dashboard, meli })=> {
  const disabled_style = {
    pointerEvents: 'none',
    opacity: 0.5,
    background: '#CCC'
  }
  const none_style = {}

  useEffect(() => {
  
    return () => {
      
    }
  }, [chart_orders])
  

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4" style={meli.profiles.length ? none_style : disabled_style}>
          <SalesOverview className="card-stretch gutter-b" />
        </div>
        {/* <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div> */}
        <div className="col-lg-6 col-xxl-8" style={meli.profiles.length ? none_style : disabled_style}>
          <ChartWidget1
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
            chart_data={chart_orders}
          />
          {/* <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          /> */}
          <ChartWidget2 className="card-stretch card-stretch-half gutter-b" chart_data={chart_categories} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <CardDeckGroup  style={meli.profiles.length ? none_style : disabled_style}/>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (reducers) => {
  return {
    chart_orders: reducers.dashboardReducer.chart_orders,
    chart_categories: reducers.dashboardReducer.chart_categories,
    resume_dashboard: reducers.dashboardReducer.resume_dashboard,
    marketplace_resume: reducers.dashboardReducer.marketplace_resume,
    meli: reducers.marketplaceReducer.meli,
    dafiti: reducers.marketplaceReducer.dafiti,
    }
}

const mapDispatchToProps = (dispatch) => {
  // dispatch(dashboardActions.fetchResumeDashboard());
  dispatch(dashboardActions.fetchChartOrders('2021-07-04','2022-08-05'));
  dispatch(dashboardActions.fetchChartCategories('2021-07-4','2022-08-05'));
  
  dispatch(meliProfileActions.fetch());
  dispatch(dafitiProfileActions.fetch());
  return {
    fetch_orders: (date_from, date_to) => dispatch(dashboardActions.fetchChartOrders(date_from, date_to)),
    fetch_categories: (date_from, date_to) => dispatch(dashboardActions.fetchChartCategories(date_from, date_to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
