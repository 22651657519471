import React, { useState, useCallback } from 'react'

import GenericSnackbar from './../../components/utils/GenericSnackbar'

const SnackbarContext = React.createContext()

export default SnackbarContext;

export const SnackbarContextProvider = ({children}) => {

  const [state, setState] = useState({
    message: null,
    open: false,
    variant: 'success'
  })

  const showSnackbar = useCallback(
    (message, variant = 'success') => {
      setState({open: true, message, variant})
    },
    []
  )

  const { message, open, variant } = state

  return (
    <SnackbarContext.Provider value={ showSnackbar }>
      {children}

      <GenericSnackbar 
        open={ open } 
        variant={ variant } 
        setOpen={ (value) => setState({...state, open: value}) } message={ message }  
      />
    </SnackbarContext.Provider>
  );
}
