import { actionTypes } from "./actions/generalActions";


const initialGeneralstate = {
  processes: [],


  // Utils 
  isFetching: false,

  // Notifications
  notifications: {
    data: [],
    totalElements: 0
  },
  //GUARDA LAS ULTIMAS 10 UNREAD Y READ
  resumeNotifications: {
    unread: [],
    read: []
  },
  // activesMarketplaces: []
}


export default (state = initialGeneralstate, action) => {

  switch (action.type) {
    case actionTypes.SetGeneralProperty:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.IsFetching:
      return {
        ...state,
        isFetching: true 
      }
    case actionTypes.IsFetchingEnd:
      return {
        ...state,
        isFetching: false 
      }
    default:
      return state;
  }

}