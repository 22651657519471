
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { actionTypes } from "./authRedux";

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
  current_suscription: undefined,
  future_suscriptions: [],
  plans: []
};

export default persistReducer(

  { storage, key: "auth-user-persist-data", whitelist: ["user", "authToken", "refreshToken"] },

  (state = initialAuthState, action) => {

    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refreshToken } = action.payload;

        return { authToken, refreshToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.PurgeSession:
        return {
          user: undefined,
          accessToken: undefined,
          refreshToken: undefined
        }
      case actionTypes.SetProperty:
        return {
          ...state,
          ...action.payload
        }

      default:
        return state;
    }
  }
);
