import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import Accounts from "../Accounts";
import UserNotificationsDropdown from "../extras/dropdowns/UserNotificationsDropdown";
import { connect, useSelector } from "react-redux";
import { actions as notificationsActions} from "../../../../app/modules/General/_redux/actions/notificationsActions";

const Topbar = (props) => {

  

  // props.fetchResumeNotifications(access_token)

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="d-flex justify-content-between">
        <Accounts/>
      
    
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}
      <UserNotificationsDropdown/>
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
    </div>
  );
}


//REDUX SETUP
const mapStateToProps = (reducers) => ({
  // notifications: reducers.generalReducer.resumeNotifications
});
const mapDispatchToProps = (dispatch) => {
  // dispatch(notificationsActions.fetchUnreadNotifications())
  return {
    // fetchResumeNotifications: (accessToken) => dispatch(notificationsActions.fetchUnreadNotifications(accessToken)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)