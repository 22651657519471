import React from 'react';
import { Spinner } from 'react-bootstrap';


/**
 * This componets show a bootstrap spinner
 * @param props.show expect a boolean value
 * @param props.size expect a size between lg|sm|md
 */

export default (props) => {
  if (props.show || props.show == undefined) 
    return (
      <Spinner
        className="mx-2"
        as="span"
        animation="border"
        size={ props.size || 'md' }
        role="status"
        aria-hidden="true"
      ></Spinner>
    );
  else 
    return (
      <span></span>
    );
}