/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import SVG from "react-inlinesvg";
import formatter from '../../utils/CurrencyFormat';
import { toAbsoluteUrl } from "../../utils/AssetsHelpers";
import { actions as dashboardActions } from "../_redux/actions/chartActions";
import { connect } from 'react-redux';

const SalesOverview = ({ className, resume_dashboard }) => {

  const [totals, setTotals] = useState({
    total_amount: 0,
    most_sell_category: '',
    total_day: 0,
    total_month: 0
  })

  useEffect(() => {
    // if(Object.keys(resume_dashboard).length > 0) {
    //   setTotals({...resume_dashboard.totals})
    // }
    return () => {
    }
  }, [resume_dashboard])


  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-danger py-5">
        <h3 className="card-title font-weight-bolder text-white">Ventas</h3>
        <div className="card-toolbar">
        </div>
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_1_chart"
          className="card-rounded-bottom bg-danger"
          style={{ height: "100px" }}
        ></div>

        {/* Stat */}
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            <div className="col bg-light-warning px-6 py-3 rounded-xl mr-7 mb-7">
              <div className="row">
                <div className="col-3 align-self-center">
                  <span className="svg-icon svg-icon-3x svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                    ></SVG>
                  </span>
                </div>
                <div className="col-9">
                  <span className="text-warning font-weight-bold font-size-h4">
                    Ventas Diarias:
                  </span>
                  <p className="text-warning font-weight-bold font-size-h2 mb-0">
                    {/* {resume_dashboard.total_amount} */}
                    {formatter.format(resume_dashboard.meli.marketplace_total_day)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col bg-light-primary px-6 py-3 rounded-xl mr-7 mb-7">
              <div className="row">
                <div className="col-3 align-self-center">
                  <span className="svg-icon svg-icon-3x svg-icon-primary d-block">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Add-user.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
                <div className="col-9">
                  <span className="text-primary font-weight-bold font-size-h6 mb-0 ">
                    Categoría más vendida:
                  </span>
                  <p className="text-primary font-weight-bold font-size-h3 mb-0">
                    {resume_dashboard.meli.most_sell_category}
                  </p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="row m-0">
            <div className="col bg-light-danger px-6 py-3 rounded-xl mr-7 mb-7">
              <div className="row">
                <div className="col-3 align-self-center">
                  <span className="svg-icon svg-icon-3x svg-icon-danger my-2">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    ></SVG>
                  </span>
                </div>
                <div className="col-9">
                  <span className="text-danger font-weight-bold font-size-h4 mb-0 ">
                    Ordenes Diarias:
                  </span>
                  <p className="text-danger font-weight-bold font-size-h2 my-0">
                    #{resume_dashboard.meli.marketplace_total_day || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col bg-light-success px-6 py-3 rounded-xl mr-7 mb-7">
              <div className="row">
                <div className="col-3 align-self-center">
                  <span className="svg-icon svg-icon-3x svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Urgent-mail.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
                <div className="col-9">
                  <span className="text-success font-weight-bold font-size-h4">
                    Ventas del Mes: 
                  </span>
                  <p className="text-success font-weight-bold font-size-h2 mb-0">
                  {formatter.format(resume_dashboard.meli.marketplace_total_month)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}

const mapStateToProps = (reducers) => {
  return {
    resume_dashboard: reducers.dashboardReducer.resume_dashboard,
    }
}

const mapDispatchToProps = (dispatch) => {
  // dispatch(dashboardActions.fetchResumeDashboard());
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesOverview);