export const typesNotifications = [
    { type: 'MPO', description: 'Pedido de productos Mercadolibre' }, // orden interna
    // {type: 'DPO', description: 'Pedido de productos Dafiti'}, // orden interna
    // {type: 'LPO', description: 'Pedido de productos Linio'}, // orden interna
    { type: 'DPE', description: 'Edición de producto Dafiti' }, // web dafiti
    { type: 'DPC', description: 'Crear producto Dafiti' }, // web dafiti
    // {type: 'DPM', description: 'Mensaje pendiente de Dafiti'},// ENVIAR A ORDEN DAFITI
    { type: 'LPE', description: 'Edición de producto Linio' }, // web linio
    { type: 'LPC', description: 'Producto crear Linio' }, // web linio
    // {type: 'LPM', description: 'Mensaje pendiente de Linio'}, //MENSAJES PENDIENTES EN ORDENES
    { type: 'MPE', description: 'Edición de producto de Mercadolibre' }, // web meli
    { type: 'MPC', description: 'Crear publicacion mercadolibre' }, // web meli
    { type: 'MPM', description: 'Mercadolibre mensaje pendiente' }, //MENSAJES PENDIENTES
    { type: 'CMM', description: 'Carga masiva Mercadolibre' },
    { type: 'EMM', description: 'Edición masiva Mercadolibre' },
    { type: 'CML', description: 'Carga masiva Linio' }, //PROCESOS
    { type: 'EML', description: 'Edición masiva Linio' },//PROCESOS
    { type: 'CMD', description: 'Carga masiva Dafiti' },//PROCESOS
    { type: 'EMD', description: 'Edición masiva Dafiti' },//PROCESOS
    { type: 'PPE', description: 'Edición de producto Pim' }, // producto pim
    {type: "IMP", description: "Importacion" },
    {type: "EXP", description: "Exportacion"},
    {type: "GEN", description: "Generico"},
    {type: "SYN", description: "Synchronization"},
    // {type: 'PPC', description: 'Crear producto Pim'} // producto pim
]

const dafitiTypes = ['DPE', 'DPC', 'CMD', 'EMD']
// const linioTypes = ['LPE', 'LPC', 'CML', 'EML']

/**
 * 
 * @param modelName "MODEL NAME DE LA NOTIFICACION"
 * @param indentificator "ID DE MODELO DE LA NOTIFICACION"
 */
export const dispatchTypeNotification = (modelName, identificator, typeNotification, process) => {
    
    if(process) {
        return window.location.href = `/general/processes/${process}/`
    }

    let resource = modelName?.split('.')[0]
    let type = modelName?.split('.')[1]

    switch (resource) {
        case 'product_information_manager':
                if(type == 'Product') {
                    window.location.href = '/pim/products/manage/'+identificator
                } 
            break;
        case 'seller_center':
                if(type == 'Product') {
                    window.location.href = `/marketplaces/${dafitiTypes.find((element) => element == typeNotification) ? 'dafiti' : 'linio'}/items/${identificator}`
                }
            break;
        case 'meli':
            if(type == 'Messages') {
                window.location.href = '/marketplaces/mercadolibre/messages'
            }

            if(type == 'Product') {
                window.location.href = '/marketplaces/meli/items/'+identificator
            }
            break;
    }
}