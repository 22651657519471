import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const PimPages = lazy(() =>
  import("./modules/Pim/PimPages")
);
const MarketplacesPages = lazy(() =>
  import("./modules/Marketplaces/MarketplacesPages")
);
const GeneralPages = lazy(() =>
  import("./modules/General/GeneralPages")
);


export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/marketplaces" component={MarketplacesPages} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/general" component={GeneralPages} />
        <Route path="/pim" component={PimPages} />

        {/** Error message for users*/}
        <Redirect to="error/not-found" />
      </Switch>
    </Suspense>
  );
}
