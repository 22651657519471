import { actionTypes } from './actions/userActions';

const initialProfileState = {
  isFetching: false,
  isSubmit: false,
  marketplace: '',
  marketplace_auth_url: '',
}

const reducer = (state = initialProfileState, action) => {

  switch (action.type) {

    case actionTypes.SetProperty:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.IsFetching:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.IsFetchingEnd:
      return {
        ...state,
        isFetching: false
      }
    case actionTypes.IsSubmit:
      return {
        ...state,
        isSubmit: true
      }
    case actionTypes.IsSubmitEnd:
      return {
        ...state,
        isSubmit: false
      }
    default: return state;
  }

}


export default reducer;