import React, { useEffect, useState } from 'react'
import { Card, Image, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../app/components/utils/AssetsHelpers'
import { connect } from 'react-redux';
import { actions as meliProfiles } from '../../../app/modules/Marketplaces/_redux/actions/mercadolibreActions/profilesActions';
import { actions as dafitiProfiles } from '../../../app/modules/Marketplaces/_redux/actions/dafitiActions/profilesActions';
import { actions as linioProfiles } from '../../../app/modules/Marketplaces/_redux/actions/linioActions/profilesActions';
import ProfileSelector from './extras/marketplaces/ProfileSelector';
import useMarketplacesActives from '../../../app/hooks/useMarketplacesActives';

const Accounts = (props) => {

    // const { marketplaces } = useMarketplacesActives()
    const [showChangeMeliProfileModal, setShowChangeMeliProfileModal] = useState(false)
    const marketplaces = JSON.parse(localStorage.getItem('marketplaces'))

    useEffect(() => {
        return () => { }
    }, [props.profiles])

    return (
        <div className='ml-2'>

            <Button className="" variant="link" onClick={() => setShowChangeMeliProfileModal(true)} style={{ backgroundColor: 'white', textDecoration: 'none' }}>
                <div className='row px-3'>
                {
                    marketplaces.MELI && props.profiles.meli && props.profiles.meli.selectedMeliProfile &&
                            <div className='mr-3'>
                                <div className='row'>
                                    <div className="col">
                                        <Image src={toAbsoluteUrl("/media/marketplaces/descarga-3.png")} style={{ minWidth: 25, maxWidth: 25 }} />
                                    </div>
                                    <div className="col">
                                        <span>{props.profiles.meli.selectedMeliProfile.nickname}</span>
                                    </div>
                                </div>
                            </div>
                }
                {
                    marketplaces.DAFITI && props.profiles.dafiti && props.profiles.dafiti.selectedDafitiProfile && props.profiles.dafiti.selectedDafitiProfile.user &&
                            <div className='mr-3'>
                                <div className='row'>
                                    <div className="col">
                                        <Image src={toAbsoluteUrl("/media/marketplaces/dafiti.png")} style={{ minWidth: 25, maxWidth: 25 }} />
                                    </div>
                                    <div className="col">
                                        <span>{props.profiles.dafiti.selectedDafitiProfile.user}</span>
                                    </div>
                                </div>
                            </div>
                }
                {
                    marketplaces.LINIO && props.profiles.linio && props.profiles.linio.selectedLinioProfile && props.profiles.linio.selectedLinioProfile.user &&
                            <div className='mr-3'>
                                <div className='row'>
                                    <div className="col">
                                        <Image src={toAbsoluteUrl("/media/svg/icons/General/logo_linio.png")} style={{ minWidth: 25, maxWidth: 25 }} />
                                    </div>
                                    <div className="col">
                                        <span>{props.profiles.linio.selectedLinioProfile.user}</span>
                                    </div>
                                </div>
                            </div>
                }
                </div>
            </Button>
            <>
                {
                    showChangeMeliProfileModal && (
                        <ProfileSelector show={showChangeMeliProfileModal} closeModal={() => setShowChangeMeliProfileModal(false)} />
                    )
                }
            </>
        </div>
    )
}


const mapStateToProps = (reducers) => {
    return {
        profiles: reducers.marketplaceReducer,
        // marketplaces: reducers.generalReducer.activesMarketplaces
    }
}

const mapDispatchToProps = (dispatch) => {
    dispatch(linioProfiles.fetch());
    dispatch(meliProfiles.fetch());
    dispatch(dafitiProfiles.fetch());

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
