import React from 'react'
import './styles.css'

const GenericSkeleton = (props) => {

    const {type} = props


    const skeleton_table = () => {
        return(
            <>
                <div className="skeleton-table">
                    <div className="skeleton-table-header">
                    </div>
                    <div className="skeleton-table-body">
                    </div>
                </div>
            </>
        )
    }

    const skeleton_card = () => {
        return(
            <>
                <div className="skeleton-card">
                    <div className="skeleton-card-image">
                    </div>
                    <div className="skeleton-card-title">
                    </div>
                    <div className="skeleton-card-body">
                    </div>
                </div> 
            </>
        )
    }

    const skeleton_block = () => {
        return(
            <>
                <div className="skeleton-block">
                    <div className="skeleton-block-content-line">
                    </div>
                    <div className="skeleton-block-content-line">
                    </div>
                    <div className="skeleton-block-content-line">
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            {
                type == 'table' && skeleton_table()
            }
            {
                type == 'card' && skeleton_card()
            }
            {
                type == 'block' && skeleton_block()
            }
        </>
    )
}

export default GenericSkeleton