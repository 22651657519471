
import axios from 'axios';
import { actions as authActions } from "../authRedux";


export const actions = {

  fetchUserInfo: () => async (dispatch) => {

    try {
      const { data, status } = await axios.get('/api/users/me/');
      if (status == 200) {
        dispatch(authActions.setUser(data));
      } 
    } catch (error) {
      console.log(error);
    }
  },
  fetchPlans: () => async (dispatch) => {

    try {

      const { data } = await axios.get('/api/memberships/plans/');

      dispatch(authActions.setProperty({ plans: data }));

    } catch (error) {

      console.log(error);

    }
  },
  chosePlan: (form, successHandler, errorHandler) => async (dispatch) => {

    try {

      const response = await axios.post('/api/memberships/manage/', form);

      dispatch(authActions.setProperty({ current_suscription: response.data }))

      successHandler(response);

    } catch (error) {

      errorHandler(error.response);
      
    }

  },
  checkout: (successHandler, errorHandler) => async (dispatch, getState) => {

    const { current_suscription } = getState().auth

    try {

      const response = await axios.post(`/api/memberships/manage/${current_suscription.id}/checkout/`, {
        method: 'for test',
        reference: '#3423874'
      });

      dispatch(authActions.setProperty({ current_suscription: response.data }));

      successHandler(response);

    } catch (error) {

      errorHandler(error.response);
      
    }

  },
  changeMembership: (plan, successHandler, errorHandler) => async (dispatch, getState) => {

    const { current_suscription } = getState().auth

    try {
      const response = await axios.post(`/api/memberships/manage/${current_suscription.id}/change/`, {
        plan: plan.id,
        method: 'for test',
        reference: '#3423874'
      });
      dispatch(authActions.setProperty({ current_suscription: response.data }));
      successHandler(response);
    } catch (error) {
      errorHandler(error.response);
    }

  },
  renew: (form, successHandler, errorHandler) => async (dispatch, getState) => {

    try {

      const { current_suscription } = getState().auth
      
      const response = await axios.post('/api/memberships/manage/renew/', form);

      successHandler(response);

      if (!current_suscription || current_suscription.status == 'F') {

        dispatch(authActions.setProperty({current_suscription: response.data}));

      }

    } catch (error) {
      console.log(error);
      errorHandler(error.response);

    }

  }
}