import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import authReducer from "../app/modules/Auth/_redux/authReducer";
import pimReducer from "../app/modules/Pim/_redux/pimReducer";
import generalReducer from './../app/modules/General/_redux/generalReducer';
// import discountReducer from "./../app/modules/Discounts/_redux/discountReducer";
import marketplaceReducer from './../app/modules/Marketplaces/_redux/marketplacesReducer';
import * as authActions from "../app/modules/Auth/_redux/authRedux.js";
import userProfileReducer from "../app/modules/UserProfile/_redux/userProfileReducer";
import dashboardReducer from "../app/components/dashboard/_redux/dashboardReducer"

export const rootReducer = combineReducers({
  auth: authReducer,
  pimReducer,
  generalReducer,
  userProfileReducer,
  marketplaceReducer,
  dashboardReducer
});

export function* rootSaga() {
  yield all([authActions.saga()]);
}
