
export const actionTypes = {
  SetGeneralProperty: '[Set general property] for any property on general reducer',
  IsFetching: '[Is fetching for loader]',
  IsFetchingEnd: '[Is fetching end for loader]',
}

export const actions = {
  setProperty: (payload) => ({ type: actionTypes.SetGeneralProperty, payload }),
  isFetching: () => ({ type: actionTypes.IsFetching }),
  isFetchingEnd: () => ({ type: actionTypes.IsFetchingEnd })
}

