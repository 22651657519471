import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

/**
 * This component use a bootstrap modal and props.children for render additional content
 * @param props.show indicator for show or hide modal dialog
 * @param props.title set title of modal dialog, default is 'Modal title'
 * @param props.handleCloseModal receive a function handler for close modal
 * @param props.size receive a string of modal dialog, default is 'lg'
 * 
 * @returns  Generic Modal Componet
 */

const GenericModal = (props) => {

  return (
    <div>
      <Modal tabIndex="-1" scrollable={props.scrollable} show={props.show} centered size={ props.size || 'lg' } onHide={props.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{ props.title || 'Modal title' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
        { props.footer_tools && (<Modal.Footer>
          {props.footer_tools}
        </Modal.Footer>)}
      </Modal>
    </div>
  );
}

GenericModal.propTypes = {
  scrollable: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
GenericModal.defaultProps = {
  scrollable: true,
  size: 'lg',
};

export default GenericModal;