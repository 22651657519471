/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import useMarketplacesActives from "../../../../../app/hooks/useMarketplacesActives";
import { connect } from "react-redux";

const AsideMenuList = ({ layoutProps }) => {
  // const {marketplaces} = useMarketplacesActives()
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const marketplaces = JSON.parse(localStorage.getItem('marketplaces'))

  useEffect(() => {
    return () => {
    }
  }, [])


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Aplicaciones</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Perfil del usuario</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* PIM */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">PIM</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/pim/products", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/products">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Barcode-scan.svg"
                )}
              />
            </span>
            <span className="menu-text">Productos</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/pim/categories", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/categories">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Dial-numbers.svg"
                )}
              />
            </span>
            <span className="menu-text">Categorías</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/pim/brands", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/brands">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Price1.svg"
                )}
              />
            </span>
            <span className="menu-text">Marcas</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/pim/warehouses", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/warehouses">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Box3.svg"
                )}
              />
            </span>
            <span className="menu-text">Bodegas</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/pim/prices", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/prices">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Dollar.svg"
                )}
              />
            </span>
            <span className="menu-text">Precios</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/pim/official_stores", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pim/official_stores">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Bag1.svg"
                )}
              />
            </span>
            <span className="menu-text">Tiendas oficiales</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Externos</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">Marketplace</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Marketplace</span>
                </span>
              </li>
              {/* Conectar */}
              <li
                className={`menu-item ${getMenuItemActive("/marketplaces/connect", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/marketplaces/connect">
                  <span className="svg-icon menu-icon">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                  </span>
                  <span className="menu-text">Conectar</span>
                </NavLink>
              </li>
              {/* Fin Conectar */}
              {/* Publicaciones */}
              <li
                className={`menu-item ${getMenuItemActive("/marketplaces/items", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/marketplaces/items">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Barcode-scan.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Publicaciones</span>
                </NavLink>
              </li>
              {/* Fin Publicaciones */}
              {/* Categorias & Atributos */}
              {/* <li
                className={`menu-item ${getMenuItemActive("/marketplaces/mercadolibre/categories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/marketplaces/mercadolibre/categories">
                  <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Dial-numbers.svg"
                    )}
                  />
                  </span>
                  <span className="menu-text">Categorias</span>
                </NavLink>
              </li> */}
              {/* Fin Categorias & Atributos */}
              {/* Ventas */}
              <li
                className={`menu-item ${getMenuItemActive("/marketplaces/orders", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/marketplaces/orders">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Dollar.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Ventas</span>
                </NavLink>
              </li>
              {/* Fin Ventas */}
              {/* Meli */}
              {/*begin::2 Level*/}
              {
                marketplaces.MELI &&
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/meli", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a className="menu-link menu-toggle">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Mercadolibre</span>
                    <i className="menu-arrow" />
                  </a>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">

                      {/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/questions"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/questions">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Preguntas</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      <li
                        className={`menu-item  ${getMenuItemActive(
                          "/marketplaces/mercadolibre/promotions"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/promotions">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Central de promociones</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/categories"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/categories">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Categorias</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/messages"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/messages">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Mensajes pendientes</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/automatic_messages"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/automatic_messages">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Mensajes Automaticos</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/size_charts"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/size_charts">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Guias de talla</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}{/*begin::3 Level*/}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/mercadolibre/catalog/elegible"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/mercadolibre/catalog/elegible">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Participar en catalogo</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                    </ul>
                  </div>
                </li>
              }
              {/* DAFITI */}
              {/*end::2 Level*/}
              {
                marketplaces.DAFITI &&
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/dafiti", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a className="menu-link menu-toggle">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Dafiti</span>
                    <i className="menu-arrow" />
                  </a>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
                      {/* Notificaciones */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/dafiti/notifications"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/dafiti/notifications">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Notificaciones</span>
                        </NavLink>
                      </li>
                      {/* Fin Notificaciones */}
                      {/* Notificaciones */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/dafiti/categories"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/dafiti/categories">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Categorias</span>
                        </NavLink>
                      </li>
                      {/* Fin Notificaciones */}
                      {/* Marcas */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/dafiti/brands"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/dafiti/brands">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Marcas</span>
                        </NavLink>
                      </li>
                      {/* Fin Marcas */}

                      {/* Atributos */}
                      {/* <li className={`menu-item  ${getMenuItemActive(
                          "/marketplaces/dafiti/attributes"
                      )}`}
                      aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                              to="/marketplaces/dafiti/attributes">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Atributos</span>
                        </NavLink>
                      </li> */}
                      {/* Fin Atributos */}
                      {/*end::3 Level*/}
                    </ul>
                  </div>
                </li>
              }

              {/*end::2 Level*/}
              {/* LINIO */}
              {/*start::2 Level*/}
              {
                marketplaces.LINIO &&
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/linio", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a className="menu-link menu-toggle">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Linio</span>
                    <i className="menu-arrow" />
                  </a>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
                      {/* Notificaciones */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/linio/notifications"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/linio/notifications">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Notificaciones</span>
                        </NavLink>
                      </li>
                      {/* Fin Notificaciones */}
                      {/* Notificaciones */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/linio/categories"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/linio/categories">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Categorias</span>
                        </NavLink>
                      </li>
                      {/* Fin Notificaciones */}
                      {/* Marcas */}
                      <li className={`menu-item  ${getMenuItemActive(
                        "/marketplaces/linio/brands"
                      )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                          to="/marketplaces/linio/brands">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Marcas</span>
                        </NavLink>
                      </li>
                      {/* Fin Marcas */}
                      {/* Atributos */}
                      {/* <li className={`menu-item  ${getMenuItemActive(
                          "/marketplaces/linio/brands"
                      )}`}
                      aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                              to="/marketplaces/linio/attributes">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Atributos</span>
                        </NavLink>
                      </li> */}
                      {/* Fin Atributos */}
                      {/*end::3 Level*/}
                    </ul>
                  </div>
                </li>
              }

              {/*end::2 Level*/}

            </ul>
          </div>
        </li>
        {/*end::1 Level*/}



        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">General</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/config", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/general/processes">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">Configuración</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Imagenes</span>
                </span>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/pim/attributes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Attribute Sets</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/products"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/pim/images">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Imagenes</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/processes", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/general/processes">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Waiting.svg"
                )}
              />
            </span>
            <span className="menu-text">Procesos</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/general/notifications">
            <span className="svg-icon menu-icon">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <i className="fas fa-bell"></i>
              </span>
            </span>
            <span className="menu-text">Notificaciones</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/** Uncomment this for discounts
         
        <li className="menu-section ">
          <h4 className="menu-text">Descuentos</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/discounts", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/discounts/basic">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Bag1.svg"
                )}
              />
            </span>
            <span className="menu-text">Gestión</span>
          </NavLink>
        </li>
         
         */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

const mapStateToProps = (reducers) => {
  return {
    // marketplaces: reducers.generalReducer.activesMarketplaces
  }
}

export default connect(mapStateToProps)(AsideMenuList)
