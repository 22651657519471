// import axios from 'axios';
// import { put, takeLatest } from "redux-saga/effects";
// // import { getUserByToken } from "./authCrud";


export const actionTypes = {
  SetProperty: '[Set property] for any property on pim reducer',
  IsFetching: '[Is Fetching] for fetch request to api starts',
  IsFetchingEnd: '[Is Fetching end] for fetch request to api ends',
  IsSubmit: '[Is Submit] for save and update submits to api',
  IsSubmitEnd: '[Is Submit] for save and update submits to api ends',
}

export const actions = {
  setProperty: (payload) => ({ type: actionTypes.SetProperty, payload }),
  isFetching: () => ({ type: actionTypes.IsFetching }),
  isFetchingEnd: () => ({ type: actionTypes.IsFetchingEnd }),
  isSubmit: () => ({ type: actionTypes.IsSubmit }),
  isSubmitEnd: () => ({ type: actionTypes.IsSubmitEnd }),
}

// export const actions = {
//   chartOrders: (labels, amounts) => ({ type: actionTypes.ChartOrders, payload: { labels, amounts } }),
//   chartCategories: (labels, amounts) => ({ type: actionTypes.ChartCategories, payload: { labels, amounts } }),
// }