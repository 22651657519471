import React, { useState, useEffect } from "react"
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Link, Redirect, useParams, useHistory  } from "react-router-dom"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import useSnackbarContext from "./../../../core/hooks/useSnackbarContext"


const PasswordReset = () => {

  const toast = useSnackbarContext()
  const history = useHistory()
  
  const { token } = useParams()

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true)
      try {
        await axios.post('/api/password_reset/validate_token/', {
          token
        })
      } catch (error) {
        toast('El enlace ya ha caducado, por favor solicita uno nuevo', 'danger')
        history.push('/auth')
      }
      setLoading(false)
    }
    validateToken()
  }, [])

  console.log(token);

  const initialValues = {
    password: '',
    token,
  }
  
  const [loading, setLoading] = useState(false)
  const [isRequested, setIsRequested] = useState(false)

  const PasswordResetschema = Yup.object().shape({
    password: Yup.string()
      .min(3, "La contraseña debe tener al menos 8 caracteres")
      .max(16, "La contraseña no debe tener mas de 16 caracteres")
      .required(
        'la contraseña es requerida'
      ),
  })

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(PasswordResetschema)

  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await axios.post('/api/password_reset/confirm/', data)
      setIsRequested(true)
    } catch (error) {
      console.log(error)
      toast('Ha ocurrido un error al reiniciar contraseña', 'danger')
      if (error.response) {
        const responseErrors = error.response.data
        for (const key of Object.keys(responseErrors)) {
          if (Array.isArray(responseErrors[key])) {
            setError(key, {message: responseErrors[key][0]})
          } else if (typeof responseErrors[key] == 'string') {
            setError(key, {message: responseErrors[key]})
          }
        }
      }
    }
    setLoading(false)
  }

  const getInputClasses = (field) => {
    if (errors[field]) {
      return 'is-invalid'
    }
  }

  const onErrors = (data) => {
    console.log(data)
    toast('Complete correctamente el formulario', 'danger')
  }

  return (
    <>
      { isRequested && <Redirect to="/auth" /> }
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <div className="text-muted font-weight-bold">
            Ingresa tu email para restablecer tu contraseña
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onErrors)}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          <div className="form-group fv-plugins-icon-container">
            <label htmlFor="password">Nueva contraseña</label>
            <input
              maxLength="16"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...register("password")}
            />
            {errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.password.message}</div>
              </div>
            )}
          </div>
          {
            errors.detail && (
              <h5 className="text-danger text-center">El token actual, ha expirado, vuelva a solicitar la recuperación</h5>
            )
          }
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={loading}
            >
              Guardar
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancelar
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default PasswordReset