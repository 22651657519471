import React, { useEffect, useState } from 'react'

import { Card } from '../../../_metronic/_partials/controls'
import GenericLayoutCard from '../utils/GenericWhiteCard'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CardDeckGroup from './widgets/CardDeckGroup';
import { connect } from 'react-redux';
import { ChartWidget1, ChartWidget2 } from './widgets';
import { actions as meliProfileActions } from "../../modules/Marketplaces/_redux/actions/mercadolibreActions/profilesActions"
import { actions as dafitiProfileActions } from "../../modules/Marketplaces/_redux/actions/dafitiActions/profilesActions"
import { actions as dashboardActions } from "./_redux/actions/chartActions";

const Dashboard2 = (props) => {
    const [fromDate, SetFromDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [toDate, SetToDate] = useState(new Date().toLocaleDateString('en-CA'));
    const disabled_style = {
        pointerEvents: 'none',
        opacity: 0.5,
        background: '#CCC'
    }

    const none_style = {}
    useEffect(()=>{
        props.fetch_dashboard(fromDate,toDate);
        props.fetch_categories(fromDate,toDate);
        props.fetch_orders(fromDate,toDate);
    },[fromDate, toDate])
    return (
        <Card className="card-stretch gutter-b">
            <div className='row mx-2 mt-3'>
                <Card className='col-12 col-md-4'>
                    <div className='row'>
                        <div className='col-12 text-center mt-2'>
                            <h3>Publicaciones totales</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 my-2'>
                            <div style={{ width: 100, height: 100 }} className='mx-auto'>
                                <CircularProgressbar 
                                    value={100} 
                                    text={`${props.resume_dashboard.publications_amount}`}  
                                />
                            </div>
                        </div>
                    </div>
                </Card>
                <Card className='col-12 col-md-4'>
                    <div className='row'>
                        <div className='col-12 text-center mt-2'>
                            <h3>Publicaciones activas</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 my-2'>
                            <div style={{ width: 100, height: 100 }} className='mx-auto'>
                                <CircularProgressbar 
                                    value={100} 
                                    text={`${props.resume_dashboard.active_publications_amount}`}  
                                />
                            </div>
                        </div>
                    </div>
                </Card>
                <Card className='col-12 col-md-4'>
                    <div className='row'>
                        <div className='col-12 text-center mt-2'>
                            <h3>Preguntas sin resolver</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 my-2'>
                            <div style={{ width: 100, height: 100 }} className='mx-auto'>
                                <CircularProgressbar 
                                    value={100} 
                                    text={`${props.resume_dashboard.unanswered_questions?.total}`}  
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className='row mx-2 mt-3'>
                {/* <CardDeckGroup  style={meli.profiles.length ? none_style : disabled_style}/> */}
                <CardDeckGroup />
            </div>
            <div className='row mx-2 mt-3'>
                <Card className='col-12'>
                    <div className='row'>
                        <ChartWidget1
                            // className="card-stretch card-stretch-half gutter-b"
                            symbolShape="circle"
                            baseColor="success"
                            chart_data={props.chart_orders}
                            className='col-12 col-md-6'
                        />
                        <ChartWidget2 
                            // className="card-stretch card-stretch-half gutter-b" 
                            chart_data={props.chart_categories} 
                            className='col-12 col-md-6'
                        />
                    </div>
                </Card>
            </div>
        </Card>
    )
}
const mapStateToProps = (reducers) => {
    return {
        chart_orders: reducers.dashboardReducer.chart_orders,
        chart_categories: reducers.dashboardReducer.chart_categories,
        resume_dashboard: reducers.dashboardReducer.resume_dashboard,
    }
}
const mapDispatchToProps = (dispatch) => {
    // dispatch(dashboardActions.fetchResumeDashboard());
    // dispatch(dashboardActions.fetchChartOrders('2021-07-04','2022-08-05'));
    // dispatch(dashboardActions.fetchChartCategories('2021-07-4','2022-08-05'));
    
    dispatch(meliProfileActions.fetch());
    //dispatch(dafitiProfileActions.fetch());
    return {
        fetch_orders: (date_from, date_to) => dispatch(dashboardActions.fetchChartOrders(date_from, date_to)),
        fetch_categories: (date_from, date_to) => dispatch(dashboardActions.fetchChartCategories(date_from, date_to)),
        fetch_dashboard: (date_from, date_to) => dispatch(dashboardActions.fetchResumeDashboard(date_from, date_to)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard2)