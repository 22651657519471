
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  actionTypes
} from "./actions/marketplacesActions";


const initialMarketplacesState = {
  meli: {
    profiles: [],
    questions: [],
    answerTemplates:[],
    selectedMeliProfile: undefined,
    categories: [],
  },
  dafiti: {
    profiles: [],
    selectedDafitiProfile: undefined,
    orders: [],
    categories: [],
  },
  linio: {
    profiles: [],
    selectedLinioProfile: undefined,
    orders: [],
    categories: [],
  },
  isFetching: false,
  isSubmit: false,
  meliProfiles: [],
  dafitiProfiles: [],
  linioProfiles: [],
  meliQuestions: [],
  category: {},
  attributes_ml: [],
  attributes: [],
  attributes_sets: [],
}


const reducer = 
  persistReducer(
    {storage, key: "auth-meli-persist-data", whitelist: ['meli']}, 
    (state = initialMarketplacesState, action) => {
      switch (action.type) {
  
        case actionTypes.SetProperty:
          return {
            ...state,
            ...action.payload
          }
        case actionTypes.IsFetching:
          return {
            ...state,
            isFetching: true
          }
        case actionTypes.IsFetchingEnd:
          return {
            ...state,
            isFetching: false
          }
        case actionTypes.IsSubmit:
          return {
            ...state,
            isSubmit: true
          }
        case actionTypes.IsSubmitEnd:
          return {
            ...state,
            isSubmit: false
          }
        default: return state;
      }
    }
  )


export default reducer;
