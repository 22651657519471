import React from "react";
import { Button } from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function ErrorPage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
        }}
      >
        {/* <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        > 
        </h1> */}
        <div className="d-flex justify-content-start">
          <img
            alt="Logo Zoco"
            src={toAbsoluteUrl("/media/logos/logo-zoco-gris.png")}
          />
        </div>
        <p className="font-size-h3 font-weight-light">
        Ups… nos disculpamos, ha ocurrido un error.
        </p>

        <div
          className="d-flex justify-content-start"
          style={{ marginTop: "30px" }}
        >
          <Button
            variant="primary"
            onClick={ () => window.location.replace("/") }
          >
            Volver al inicio
          </Button>
        </div>
      </div>
    </div>
  );
}
