
export const actionTypes = {
  SetProperty: '[Set property] for any property on pim reducer',
  IsFetching: '[Is Fetching] for fetch request to api starts',
  IsFetchingEnd: '[Is Fetching end] for fetch request to api ends',
  IsSubmit: '[Is Submit] for save and update submits to api',
  IsSubmitEnd: '[Is Submit] for save and update submits to api ends',
}

export const actions = {
  setProperty: (payload) => ({ type: actionTypes.SetProperty, payload }),
  isFetching: () => ({ type: actionTypes.IsFetching }),
  isFetchingEnd: () => ({ type: actionTypes.IsFetchingEnd }),
  isSubmit: () => ({ type: actionTypes.IsSubmit }),
  isSubmitEnd: () => ({ type: actionTypes.IsSubmitEnd }),
}
