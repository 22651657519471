
import axios from 'axios';
import { actions as dashboardActions } from "../dashboardRedux";


export const actions = {

  fetchChartOrders: (from_date, to_date) => async (dispatch) => {

    try {
      dispatch(dashboardActions.isFetching());
      const { data } = await axios.get('api/stats/charts/orders_detail/',{
        params: {
          from_date, to_date
        }
      });

      dispatch(dashboardActions.setProperty({ chart_orders: {data} }));

      dispatch(dashboardActions.isFetchingEnd());
    } catch (error) {
      console.log(error);
    }
  },
  fetchChartCategories: (from_date, to_date) => async (dispatch) => {

    try {
      dispatch(dashboardActions.isFetching());
      const { data } = await axios.get('api/stats/charts/categories_detail/',{
        params: {
          from_date, to_date
        }
      });

      dispatch(dashboardActions.setProperty({ chart_categories: {data} }));

      dispatch(dashboardActions.isFetchingEnd());
    } catch (error) {
      console.log(error);
    }
  },
  fetchResumeDashboard: (date_from, date_to) => async (dispatch) => {

    try {
      dispatch(dashboardActions.isFetching());
      // const { data } = await axios.get('api/stats/charts/resume_dashboard/');
      const { data } = await axios.get('api/users/stats/',{params:{
          from_date: date_from,
          to_date: date_to
      }
      });
      dispatch(dashboardActions.setProperty({ resume_dashboard: {...data} }));

      dispatch(dashboardActions.isFetchingEnd());
    } catch (error) {
      console.log(error);
    }
  },
  fetchResumeMarketPlaces: () => async (dispatch) => {
    try {
      dispatch(dashboardActions.isFetching());
      // console.log(`BEFORE RESPONSE DAFITI`)
      // const response_dafiti = await axios.get('api/seller_center/dafiti/metrics/');
      // console.log(`AFTER RESPONSE DAFITI`)
      // const response_linio = await axios.get('api/seller_center/linio/metrics/');
      // console.log(`AFTER RESPONSE LINIO`)
      const response_meli = await axios.get('api/stats/charts/resume_dashboard/');

      // let metric_dafiti = {
      //   marketplace_total_day: response_dafiti.data.filter(metric => metric.statictics_type === "day")[0].sales_total ,
      //   marketplace_total_week: response_dafiti.data.filter(metric => metric.statictics_type === "week")[0].sales_total,
      //   marketplace_total_month: response_dafiti.data.filter(metric => metric.statictics_type === "month")[0].sales_total,
      //   marketplace_total: response_dafiti.data.filter(metric => metric.statictics_type === "alltime")[0].sales_total
      // }

      // let metric_linio = {
      //   marketplace_total_day: response_linio.data.filter(metric => metric.statictics_type === "day")[0].sales_total ,
      //   marketplace_total_week: response_linio.data.filter(metric => metric.statictics_type === "week")[0].sales_total,
      //   marketplace_total_month: response_linio.data.filter(metric => metric.statictics_type === "month")[0].sales_total,
      //   marketplace_total: response_linio.data.filter(metric => metric.statictics_type === "alltime")[0].sales_total
      // }
      let metric_dafiti = {
        marketplace_total_day: 0,
        marketplace_total_week: 0,
        marketplace_total_month: 0,
        marketplace_total: 0
      }

      let metric_linio = {
        marketplace_total_day: 0,
        marketplace_total_week: 0,
        marketplace_total_month: 0,
        marketplace_total: 0
      }

      let metric_meli = {
        marketplace_total_day: response_meli.data.totals.total_day ,
        marketplace_total_week: response_meli.data.totals.total_day,
        marketplace_total_month: response_meli.data.totals.total_month,
        marketplace_total: response_meli.data.totals.total_amount,

      }      
      // dispatch(dashboardActions.setProperty({ resume_dashboard: {...data} }));
      dispatch(dashboardActions.setProperty({ resume_marketplaces: {meli: {...metric_meli}, dafiti: {...metric_dafiti}, linio: {...metric_linio}} }));

      dispatch(dashboardActions.isFetchingEnd());
    } catch (error) {
      console.log(`ERRPR EN RESUME MARKETPLACES: ${error}`);
    }
  },

}