import axios from 'axios';
import { actions as marketplaceAction } from '../marketplacesActions';
import { actions as accountActions } from "../../../../Auth/_redux/actions/profilesActions";
import { actions as authActions } from "../../../../Auth/_redux/authRedux";
import { isInteger } from 'lodash';


export const actionTypes = {
  changePriority: '[Change Priority] Change profile priority'
}

export const actions = {

    fetch: () =>  async (dispatch, getState) => {
  
      dispatch(marketplaceAction.isFetching());
  
      try {
        const response = await axios.get(`/api/seller_center/linio/profiles/`);
        const { linio } = getState().marketplaceReducer
        let newSelectedLinioProfile = linio.selectedLinioProfile;
        let profiles = {};
        if (response.data.results.length == 0){
          profiles = { linio: { ...linio, selectedLinioProfile: null, profiles: response.data.results }};
        } else {
          if (newSelectedLinioProfile){
            let profile_find = response.data.results.filter(profile => {
              return profile.id == newSelectedLinioProfile.id;
            });
            if (!profile_find){
              newSelectedLinioProfile = response.data.results.length > 0 ? response.data.results[0]:null;
            }
          } else {
            newSelectedLinioProfile = response.data.results.length > 0 ? response.data.results[0]:null;
          }

          profiles = { linio: { ...linio, selectedLinioProfile: {...newSelectedLinioProfile}, profiles: response.data.results }}
        }

        
        
        let temp = []
        
        profiles.linio.profiles.forEach((element) => {
          if(element.is_active){
            temp.push(element)
          }
        })

        dispatch(marketplaceAction.setProperty(profiles));
        
      } catch (error) {
  
        //console.log(error);
        
      }
  
      dispatch(marketplaceAction.isFetchingEnd());
  
    },

    removeGrant: (profile_id, successHandler = null, errorHandler = null) => async (dispatch, getState) => {
      dispatch(marketplaceAction.isSubmit());
      let profiles = {};
      try {
  
        const { data } = await axios.patch(`/api/seller_center/linio/profiles/${profile_id}/deactivate/`, {'id': profile_id});
        const { linio } = getState().marketplaceReducer
        let new_profiles = linio.profiles.filter(profile => {
          return profile.id !== profile_id;
        });
  
        dispatch(marketplaceAction.setProperty(profiles));
        dispatch(actions.fetch());
        if (successHandler) {
          dispatch(accountActions.fetchUserInfo());
          successHandler(data.results);
        }
  
      } catch (error) {
  
        errorHandler(error.response)
        
      }
  
      dispatch(marketplaceAction.isSubmitEnd());
      
    },
    changePriority: (profile_id, callback = null, fail_callback = null) => async (dispatch, getState) => {
      try {
        const { data } = await axios.put(`/api/seller_center/linio/profiles/${profile_id}/main/`);
        const { linio } = getState().marketplaceReducer;
        dispatch(marketplaceAction.setProperty(
          {
            linio: {
              ...linio,
              selectedLinioProfile: {...data},
              profiles: linio.profiles.map(mapped_profile => {
                if (profile_id == mapped_profile.id) {
                  return data
                } else {
                  return mapped_profile
                }
              })
            }
          }
        ))
        if (callback) {
          callback()
        }
      } catch (error) {
        if (fail_callback) {
          fail_callback(error)
        }
        console.log(error)
        
      }

    }
  
  }
  