import { actions } from "./../app/modules/Auth/_redux/authRedux";
//import { REFRESH_TOKEN_URL } from "./../app/modules/Auth/_redux/authCrud";

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000";
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken },
        marketplaceReducer: { meli, dafiti }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      if (meli.selectedMeliProfile) {
        config.headers.common["MeliProfile-id"] = meli.selectedMeliProfile.idmeli
      }
      if (dafiti.selectedDafitiProfile){
        config.headers.common["DafitiProfile-id"] = dafiti.selectedDafitiProfile.id
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => {
      return response;
    }, 
    error => {
      if (error.response.status === 401) {
        // const originalRequest = error.config;
        // const { refreshToken } = store.getState().auth;
        // axios.post(REFRESH_TOKEN_URL, {
        //   refresh: refreshToken
        // })
        //   .then(({ data, status }) => {
        //     if (status === 200) {
        //       store.dispatch(actions.setProperty({ authToken: data.access }))
        //       return axios(originalRequest);
        //     }
        //   })
          // .catch(() => {
            store.dispatch(actions.purgeSession())
          // })
      }

      return Promise.reject(error);
    }
  );
}
