/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { connect, useSelector } from "react-redux";
import { actions as notificationsActions } from "../../../../../app/modules/General/_redux/actions/notificationsActions";
import { typesNotifications, dispatchTypeNotification } from "../../../../../app/components/utils/typesNotifications";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const UserNotificationsDropdown = (props) => {
  const [key, setKey] = useState("unread");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const [readNotifications, setReadNotifications] = useState([])
  const [count, setCount] = useState(0)

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const ws_url = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:8004'
  const access_token = useSelector(store => store.auth?.authToken);
  const ws = new WebSocket(`${ws_url}/ws?token=${access_token}`);

  const apiCall = {
    event: "data",
    data: {},
  };

  ws.onopen = (event) => {
    ws.send(JSON.stringify(apiCall));
  };

  ws.onmessage = function (event) {
    const json = JSON.parse(event.data);
    try {
      setCount(json.unread_notifications);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      ws.send(JSON.stringify(apiCall));
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setUnreadNotifications(props.notifications.unread)
    setReadNotifications(props.notifications.read)

    return () => {

    }
  }, [props.notifications.unread, props.notifications.read])

  const handleClickNotification = async (notification) => {
    await props.setReadNotification(notification.id)
    dispatchTypeNotification(notification.model_name, notification.model_id, notification.notification_type, notification.process)
  }


  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item" >
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight >
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notificaciones
                </Tooltip>
              }
            >
              <button 
                type="button" 
                className="btn btn-outline-light mr-2 p-1"
                onClick={() => props.fetchResumeNotifications()}
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <i className="fas fa-bell"></i>
                    <span className="badge badge-light">{count > 1000 ? '+1000' : count}</span>
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </button>

            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notificaciones</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {count}
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="unread"
                        className={`nav-link show ${key === "unread" ? "active" : "" }`}
                        style={{ textShadow: 'unset' }}
                      >
                        No Leida
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="read"
                        className={`nav-link show ${key === "read" ? "active" : ""
                          }`}
                      >
                        Leidas
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="unread"
                      className="p-8"
                      style={{ textShadow: 'unset' }}
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <></>
                        {/* NOTIFICACIONES SIN LEER */}
                        {
                          unreadNotifications.map((notification) => {
                            let text = notification.notification_type == 'MPO'  ? 
                              `Nuevo pedido de ${
                                notification.content.split('Nueva venta en mercado libre ')[1].split("'title':")[1].split("'")[1]
                              }` :
                              notification.content
                            return (
                              <div 
                                key={notification.id} 
                                className="d-flex align-items-center mb-6" 
                                onClick={() => handleClickNotification(notification)}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                      <i className="fas fa-bell"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold">
                                  <div
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    {notification.description}
                                  </div>
                                  <span className="text-muted">
                                    {text}
                                    <br/>
                                    {`Fecha: ${notification.date} ${notification.time}`}
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }


                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="read"
                      id="topbar_notifications_events"
                      style={{ color: 'unset', shadow: 'unset', textShadow: 'unset' }}
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {/* NOTIFICACIONES LEIDAS */}
                        {
                          readNotifications.map((notification) => {
                            let text = notification.notification_type == 'MPO'  ? 
                              `Nuevo pedido de ${
                                notification.content.split('Nueva venta en mercado libre ')[1].split("'title':")[1].split("'")[1]
                              }` :
                              notification.content
                            return (
                              <a href="#" className="navi-item" key={notification.id} >
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="fas fa-eye"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold" >
                                      {text}
                                    </div>
                                    {/* <div className="text-muted">23 hrs ago</div> */}
                                  </div>
                                </div>
                              </a>
                            )
                          })
                        }
                        {
                          readNotifications.length == 0 ? 
                          <div className="d-flex justify-content-center">
                            <span className="text-center">No existen notificaciones 📭</span>
                          </div>
                          : <></>
                        }
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

//REDUX SETUP
const mapStateToProps = (reducers) => ({
  notifications: reducers.generalReducer.resumeNotifications
});
const mapDispatchToProps = (dispatch) => {
  // dispatch(notificationsActions.fetchResumeNotifications())
  return {
    fetchResumeNotifications: () => dispatch(notificationsActions.fetchResumeNotifications()),
    setReadNotification: (id) => dispatch(notificationsActions.setReadNotification(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsDropdown)