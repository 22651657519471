import {
  actionTypes
} from "./actions/pimActions";


const initialPimState = {
  isFetching: false,
  isSubmit: false,
  categories: {data:[], count:0},
  parent_categories:[],
  attributes: [],
  sku_types: [], 
  brands: [],
  warehouses: [],
  tags: [],
  products: [],
  official_stores: [],
  attributes_sets: [],
  prices: [],
  processes: [],
  images: null,
  // utils for search
  searchBrandText: '',
  searchCategoryText: '',
  searchAttributesSetText: '',
  searchProductText:'',
  // Utils for edit
  brand_on_editing: "",
  attributes_set_on_editing: {
    attributes_groups: []
  },
  warehouse_on_editing: {
    warehouse_products: []
  },
  product_on_editing: null,
}


const reducer = (state = initialPimState, action) => {

    switch (action.type) {

      case actionTypes.SetProperty:
        return {
          ...state,
          ...action.payload
        }
      case actionTypes.IsFetching:
        return {
          ...state,
          isFetching: true
        }
      case actionTypes.IsFetchingEnd:
        return {
          ...state,
          isFetching: false
        }
      case actionTypes.IsSubmit:
        return {
          ...state,
          isSubmit: true
        }
      case actionTypes.IsSubmitEnd:
        return {
          ...state,
          isSubmit: false
        }
      default: return state;
    }

  }


export default reducer;