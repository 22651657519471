import React, {useEffect, useState} from "react";
import { CardDeck, Card } from "react-bootstrap";
import formatter from '../../utils/CurrencyFormat';
import { toAbsoluteUrl } from "../../utils/AssetsHelpers";
import { connect } from 'react-redux';
import { actions as dashboardActions } from "../_redux/actions/chartActions";

const CardDeckGroup = (props) => {
  const { style } = props;
  const disabled_style = {
    pointerEvents: 'none',
    opacity: 0.5,
    background: '#CCC'
  }
  useEffect(()=>{
    props.fetch_resume_marketplaces();
  },[])
  const [resume, setResume] = useState({
    dafiti: {
      marketplace_total_day: 0,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0
    },
    meli: {
      marketplace_total_day: 0,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0
    },
    linio: {
      marketplace_total_day: 0,
      marketplace_total_week: 0,
      marketplace_total_month: 0,
      marketplace_total: 0
    }
  })

  useEffect(() => {
    console.log(`MARKETPLACES RESUME: ${JSON.stringify(props.resume_marketplaces)}`)
    if (props.resume_marketplaces != null)
    {
      setResume(props.resume_marketplaces);
    } 
    return () => {
    }
  }, [props.resume_marketplaces])

  return (<>
    <CardDeck>
      <Card style={style} border="secondary" className="color-primary">
        <Card.Body>                                                                                                                                                  
          <div className="row">
            <div className="col-8">
              <h5>Mercado Libre</h5>
              <li><b>Ventas Diarias:</b> {formatter.format( resume.meli ? resume.meli.marketplace_total_day : 0 )}</li>
              <li><b>Ventas Semanales:</b> {formatter.format( resume.meli? resume.meli.marketplace_total_week : 0 )}</li>
              <li><b>Ventas Mensuales:</b> {formatter.format( resume.meli? resume.meli.marketplace_total_month : 0)}</li>
              <li><b>Ventas Totales:</b> {formatter.format( resume.meli? resume.meli.marketplace_total : 0)}</li>
            </div>
            <div className="col-4">
              <img src={toAbsoluteUrl("/media/marketplaces/descarga-3.png")} alt="" className="img-fluid" />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card border="secondary">
        <Card.Body>
          <div className="row">
            <div className="col-8">
              <h5>Dafiti</h5>
              <li><b>Ventas Diarias:</b> {formatter.format( resume.dafiti? resume.dafiti.marketplace_total_day : 0 )}</li>
              <li><b>Ventas Semanales:</b> {formatter.format( resume.dafiti? resume.dafiti.marketplace_total_week : 0)}</li>
              <li><b>Ventas Mensuales:</b> {formatter.format( resume.dafiti? resume.dafiti.marketplace_total_month : 0)}</li>
              <li><b>Ventas Totales:</b> {formatter.format( resume.dafiti? resume.dafiti.marketplace_total : 0 )}</li>
            </div>
            <div className="col-4">
              <img src={toAbsoluteUrl("/media/marketplaces/dafiti.png")} className="img-fluid" alt="" />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card border="secondary">
        <Card.Body>
          <div className="row">
            <div className="col-8">
              <h5>Linio</h5>
              <li><b>Ventas Diarias:</b> {formatter.format( resume.linio? resume.linio.marketplace_total_day : 0 )}</li>
              <li><b>Ventas Semanales:</b> {formatter.format( resume.linio? resume.linio.marketplace_total_week : 0 )}</li>
              <li><b>Ventas Mensuales:</b> {formatter.format( resume.linio? resume.linio.marketplace_total_month : 0 )}</li>
              <li><b>Ventas Totales:</b> {formatter.format( resume.linio? resume.linio.marketplace_total : 0 )}</li>
            </div>
            <div className="col-4">
              <img src={toAbsoluteUrl("/media/marketplaces/Linio.png")} className="img-fluid" alt="" />
            </div>
          </div>
        </Card.Body>
      </Card>
    </CardDeck>
  </>)
}

const mapStateToProps = (reducers) => {
  return {
      resume_marketplaces: reducers.dashboardReducer.resume_marketplaces
  }
}

const mapDispatchToProps = (dispatch) => {
  // dispatch(dashboardActions.fetchResumeDashboard());
  dispatch(dashboardActions.fetchResumeMarketPlaces());
  return {

    fetch_resume_marketplaces: () => dispatch(dashboardActions.fetchResumeMarketPlaces())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDeckGroup);