
import axios from 'axios';
import { actions as marketplaceAction } from './../../actions/marketplacesActions';
import { actions as accountActions } from "../../../../Auth/_redux/actions/profilesActions";
import { actions as authActions } from "../../../../Auth/_redux/authRedux";
import { isInteger } from 'lodash';


export const actionTypes = {
  changePriority: '[Change Priority] Change profile priority'
}


export const actions = {

  fetch: () =>  async (dispatch, getState) => {

    dispatch(marketplaceAction.isFetching());

    try {
      const response = await axios.get(`/api/meli/profiles/`);

      const { meli } = getState().marketplaceReducer
      let newSelectedMeliProfile = meli.selectedMeliProfile;
      let profiles = {};
      if (response.data.results.length == 0){
        profiles = { meli: { ...meli, selectedMeliProfile: null, profiles: response.data.results }};
      } else {
        if (newSelectedMeliProfile){
          let profile_find = response.data.results.filter(profile => {
            return profile.id == newSelectedMeliProfile.id;
          });
          if (!profile_find){
            newSelectedMeliProfile = response.data.results.length > 0 ? response.data.results[0]:null;
          }
        } else {
          newSelectedMeliProfile = response.data.results.length > 0 ? response.data.results[0]:null;
        }
        let flex_status = "out";
        const {data} = await axios.get(`/api/meli/profiles/${newSelectedMeliProfile.id}/flex/`);
        let response_data = data;
        flex_status = response_data.configuration.adoption.status;
        profiles = { meli: { ...meli, selectedMeliProfile: {...newSelectedMeliProfile, flex: flex_status}, profiles: response.data.results }}
      }

      dispatch(marketplaceAction.setProperty(profiles));
      
    } catch (error) {

      //console.log(error);
      
    }

    dispatch(marketplaceAction.isFetchingEnd());


  },
  profileStats: (profile_id, idmeli, successHandler = null, errorHandler = null) =>  async (dispatch, getState) => {

    //dispatch(marketplaceAction.isFetching())

    try {

      let stats;
      var { data } = await axios.get(`/api/meli/profiles/${profile_id}/user_quality/`,{
        headers: {
          common:{
            'MeliProfile-Id': idmeli
          } 
        }
      });
      stats = data;
      var { data } = await axios.get(`/api/meli/profiles/${profile_id}/items_count/`,{
        headers: {
          common:{
            'MeliProfile-Id': idmeli
          } 
        }
      });
      if (isInteger(data)){
        stats = {...stats, ...{total:data}};
      } else {
        stats = {...stats, ...data};
      }

      if (successHandler)
        successHandler(stats, profile_id);
      else 
        return {stats, profile_id}
    } catch (error) {

      //console.log(error);
      if (errorHandler)
        errorHandler(error);
      
    }

    //dispatch(marketplaceAction.isFetchingEnd());

  },

  removeGrant: (profile_id, successHandler = null, errorHandler = null) => async (dispatch, getState) => {
    dispatch(marketplaceAction.isSubmit());
    try {

      const { data } = await axios.delete(`/api/meli/profiles/${profile_id}/remove_grant/`);
      const { meli } = getState().marketplaceReducer
      let new_profiles = meli.profiles.filter(profile => {
        return profile.id !== profile_id;
      });
      let new_selected_profile = new_profiles.length > 0 ? new_profiles[0]:null;
      
      let flex_status = "out";
      const response = await axios.get(`/api/meli/profiles/${new_selected_profile.id}/flex/`);
      let response_data = response.data;
      flex_status = response_data.configuration.adoption.status;
      let profiles = { meli: { ...meli,
        selectedMeliProfile: {...new_selected_profile, flex: flex_status},
        profiles: new_profiles }}

      dispatch(marketplaceAction.setProperty(profiles));
      dispatch(actions.fetch());
      if (successHandler) {
        dispatch(accountActions.fetchUserInfo());
        successHandler(data);
      }

    } catch (error) {

      errorHandler(error.response)
      
    }

    dispatch(marketplaceAction.isSubmitEnd());
    
  },
  changePriority: (profile_id, callback = null, fail_callback = null) => async (dispatch, getState) => {
    try {
      const { data } = await axios.put(`/api/meli/profiles/${profile_id}/main/`);
      const { meli } = getState().marketplaceReducer;
      let flex_status = "out";
      const response = await axios.get(`/api/meli/profiles/${profile_id}/flex/`);
      let response_data = response.data;
      flex_status = response_data.configuration.adoption.status;
      dispatch(marketplaceAction.setProperty(
        {
          meli: {
            ...meli,
            selectedMeliProfile: {...data, flex: flex_status},
            profiles: meli.profiles.map(mapped_profile => {
              if (profile_id == mapped_profile.id) {
                return data
              } else {
                return mapped_profile
              }
            })
          }
        }
      ))
      if (callback) {
        callback()
      }
    } catch (error) {
      if (fail_callback) {
        fail_callback(error)
      }
      console.log(error)
      
    }

  }

}
