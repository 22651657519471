import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Button, Badge } from 'react-bootstrap'
import { connect } from 'react-redux'


import { actions as meliProfilesActions } from "../../../../../app/modules/Marketplaces/_redux/actions/mercadolibreActions/profilesActions"
import { actions as dafitiProfilesActions } from "../../../../../app/modules/Marketplaces/_redux/actions/dafitiActions/profilesActions"
import { actions as linioProfilesActions } from "../../../../../app/modules/Marketplaces/_redux/actions/linioActions/profilesActions"

import GenericModal from '../../../../../app/components/utils/GenericModal'
import useSnackbarContext from '../../../../../app/core/hooks/useSnackbarContext'
import useMarketplacesActives from '../../../../../app/hooks/useMarketplacesActives'



const ProfileSelector = (props) => {

  const toast = useSnackbarContext()
  // const mks = useMarketplacesActives()

  const mks = JSON.parse(localStorage.getItem('marketplaces'))

  const default_option = { label: 'Elija una opción', value: '' }

  const [currentOption, setCurrentOption] = useState(default_option)
  const [meliProfiles, setMeliProfiles] = useState([default_option])
  const [linioProfiles, setLinioProfiles] = useState([default_option])
  const [dafitiProfiles, setDafitiProfiles] = useState([default_option])
  const [marketPlaceSelected, setMarketPlaceSelected] = useState(default_option)
  const [showLoadingModal, setShowLoadingModal] = useState(false)

  let marketPlaces = [
    { label: 'MercadoLibre', value: 0},
    { label: 'Dafiti', value: 1},
    { label: 'Linio', value: 2}
  ]

  if(!mks.MELI) {
    delete marketPlaces[0]
  }
  if(!mks.DAFITI) {
    delete marketPlaces[1]
  }
  if(!mks.LINIO) {
    delete marketPlaces[2]
  }

  useEffect(() => {

    let final_array = [
      default_option,
      ...props.meliData.profiles.map(profile => ({
        label: <>{profile.nickname}{<MshopsBadge profile={profile}/>}</>,
        value: profile.id
      }))
    ]
    setMeliProfiles(props.meliData.profiles)

    setCurrentOption(default_option);

    setDafitiProfiles(props.dafitiData.profiles)
    setLinioProfiles(props.linioData.profiles)
  }, [props.meliData.profiles, props.dafitiData.profiles, props.linioData.profiles])


  const handleChangePriorityProfile = async () => {
    if (marketPlaceSelected.value == 0){
      props.changeMeliPriority(
        currentOption.value, 
        () => {
          toast('Perfil cambiado exitosamente')
          showModal()
          props.closeModal()
        },
        () => {
          toast('Ha ocurrido un error al cambiar perfíl de mercadolibre', 'danger')
        }
      )
    } else if (marketPlaceSelected.value == 1) {
      props.changeDafitiPriority(
        currentOption.value, 
        () => {
          toast('Perfil cambiado exitosamente')
          showModal()
          props.closeModal()
        },
        () => {
          toast('Ha ocurrido un error al cambiar perfíl de dafiti', 'danger')
        }
      )
    } else if (marketPlaceSelected.value == 2) {
      props.changeLinioPriority(
        currentOption.value, 
        () => {
          toast('Perfil cambiado exitosamente')
          showModal()
          props.closeModal()
        },
        () => {
          toast('Ha ocurrido un error al cambiar de perfíl de linio', 'danger')
        }
      )
    }

    
    //recarga se sitio para cargar data de nuevo perfil seleccionado
    function showModal() {
      setShowLoadingModal(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000);  
    }
    
    

  }

  const LoadingChangeAccountModal = () => {
    return(
      <>
        <GenericModal title="Preparando todo..." size="md" show={ true } handleCloseModal={ () => {} } >
          <div class="d-flex justify-content-center">
            <h5>CARGANDO DATOS DEL NUEVO PERFIL SELECCIONADO</h5>
            <br/>
            <h1>
              <i className="fas fa-circle-notch fa-spin fa-lg"></i>
            </h1>
          </div>
        </GenericModal>
      </>
    )
  }

  const MshopsBadge = (props) => {
    const { profile } = props;
    const mshops_available = profile.tags && profile.tags.includes("mshops")
    return (<>
    {""} {(mshops_available) && <Badge pill variant={"info"}>MShops</Badge>}
    </>)
  }
  const linioProfileOptions = () => linioProfiles && linioProfiles.filter((option)=> option.id != props.linioData.selectedLinioProfile.id).map((option) => ({marketPlace: `linio`, value: option.id, label: option.user}));
  const dafitiProfileOptions = () => dafitiProfiles && dafitiProfiles.filter((option)=> option.id != props.dafitiData.selectedDafitiProfile.id).map((option) => ({marketPlace: `dafiti`, value: option.id, label: option.user}));
  const meliProfileOptions = () => meliProfiles && meliProfiles.filter((option)=> option.id != props.meliData.selectedMeliProfile.id).map((option) => ({marketPlace: `meli`, value: option.id, label: <>{option.nickname}{<MshopsBadge profile={option}/>}</>}));


  // console.log("LINIO PRTOFILES", linioProfileOptions())
  return (
    <GenericModal title="Cambiar de perfil" size="md" show={ props.show } handleCloseModal={ () => props.closeModal() } >
      {
        showLoadingModal &&
        <LoadingChangeAccountModal />
      }
      <h6 className="mb-3"> 
      ¿Que marketplace deseas editar?
      </h6>

      <Select 
        options={ marketPlaces }
        value={marketPlaceSelected}
        onChange={ (option) => setMarketPlaceSelected(option) }
      />
      <hr></hr>
      {
        marketPlaceSelected.value === 0 &&
        <div className="m-1">
            <h5>Perfiles Mercado Libre</h5>
            {
              props.meliData.selectedMeliProfile ? (
                'El perfil actual es: "' + props.meliData.selectedMeliProfile.nickname + '"'
              ) : 'Ningún perfil seleccionado'
            } 
            <MshopsBadge profile={props.meliData.selectedMeliProfile}/>
            <div className="row">
              <div className="col-12">
                <Select 
                  options={ meliProfileOptions() }
                  value={ currentOption }
                  onChange={ (option) => setCurrentOption(option) }
                  isDisabled={ meliProfileOptions().length == 0 }
                />
              </div>
            </div>
          </div>
      }
      {
        marketPlaceSelected.value === 1 &&
        <div className="m-1">
          <h5>¿Que perfíl desea utilizar?</h5>
            {
              props.dafitiData.selectedDafitiProfile ? (
                'El perfil actual es: "' + props.dafitiData.selectedDafitiProfile.user + '"'
              ) : 'Ningún perfil seleccionado'
            } 
          <div className="row">
            <div className="col-12">
              <Select
                options={dafitiProfileOptions()}
                value={currentOption}
                onChange={(option) => setCurrentOption(option)}
                isDisabled={ dafitiProfileOptions().length == 0 }
              />
            </div>
          </div>
        </div>
      }
      {
        marketPlaceSelected.value === 2 &&
        <div className="m-1">
          <h5>¿Que perfíl desea utilizar?</h5>
            {
              props.linioData.selectedLinioProfile ? (
                'El perfil actual es: "' + props.linioData.selectedLinioProfile.user + '"'
              ) : 'Ningún perfil seleccionado'
            } 
          <div className="row">
            <div className="col-12">
              <Select
                options={linioProfileOptions()}
                value={currentOption}
                onChange={(option) => setCurrentOption(option)}
                isDisabled={ linioProfileOptions().length == 0 }
              />
            </div>
          </div>
        </div>
      }

      <div className="d-flex justify-content-end mt-4">
        {
          marketPlaces.map((x) => {return x.value}).includes(marketPlaceSelected.value) &&
          <Button 
            onClick={ () => {handleChangePriorityProfile()} }
            disabled={currentOption.value == ''}
          >Guardar</Button>
        }
      </div>

    </GenericModal>
  )
}
const mapStateToProps = (reducers) => {
  return {
    meliData: reducers.marketplaceReducer.meli,
    dafitiData: reducers.marketplaceReducer.dafiti,
    linioData: reducers.marketplaceReducer.linio,
    // marketplaces: reducers.generalReducer.activesMarketplaces
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(meliProfilesActions.fetch());
  dispatch(dafitiProfilesActions.fetch());  
  dispatch(linioProfilesActions.fetch());  
  return {
    changeMeliPriority: (profile_id, callback, fail_callback) => dispatch(meliProfilesActions.changePriority(profile_id, callback, fail_callback)),
    changeDafitiPriority: (profile_id, callback, fail_callback) => dispatch(dafitiProfilesActions.changePriority(profile_id, callback, fail_callback)),
    changeLinioPriority: (profile_id, callback, fail_callback) => dispatch(linioProfilesActions.changePriority(profile_id, callback, fail_callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelector)
