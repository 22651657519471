import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";


const GenericLayoutCard = (props) => {

  const title = props.title || 'Zoco'

  const withHeader = props.withHeader || true;
  const subtitle = props.subtitle || ''

  const suhbeader = useSubheader();

  suhbeader.setTitle(title);

  return (
    <>
      <Card>
        {
          withHeader && (
            <CardHeader title={subtitle}>
              <CardHeaderToolbar>
                {props.card_header_actions}
              </CardHeaderToolbar>
            </CardHeader>
          )
        }
        <CardBody>
          {props.children}
        </CardBody>
      </Card>
    </>
  )
}

export default GenericLayoutCard;