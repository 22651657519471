import axios from 'axios';
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

import { actions as marketplacesActions } from "./../../Marketplaces/_redux/actions/marketplacesActions"


export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  PurgeSession: '[Purgue Session] Purgue all session data',
  SetProperty: '[Set  Property] Set any property on reducer',
};

export const actions = {
  login: (authToken, refreshToken) => ({ type: actionTypes.Login, payload: { authToken, refreshToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => {
    return {
      type: actionTypes.UserRequested,
      payload: { user },
    }
  },
  fulfillUser: (user) => (dispatch, getState) => {
    const { account } = user 

    if (account.meli_profile && account.meli_profile.length > 0)  {
      const { meli } = getState().marketplaceReducer
      const new_meli_data = {meli: {...meli, profiles: account.meli_profile, selectedMeliProfile: account.meli_profile[0]}}
      dispatch(marketplacesActions.setProperty(new_meli_data))
    }
    if (account.dafiti_profile && account.dafiti_profile.length > 0)  {
      const { dafiti } = getState().marketplaceReducer
      const new_dafiti_data = {dafiti: {...dafiti, profiles: account.dafiti_profile, selectedDafitiProfile: account.dafiti_profile[0]}}
      dispatch(marketplacesActions.setProperty(new_dafiti_data))
    }
    dispatch({ type: actionTypes.UserLoaded, payload: { user } })
    
  },
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setProperty: (payload) => ({type: actionTypes.SetProperty, payload}),
  purgeSession: () => ({type: actionTypes.PurgeSession}),
  updateUser: (form, responsesucceedHandler, responseErrorHandler) => async (dispatch) => {

    try {

      const response = await axios.post('/api/users/edit/', form);

      dispatch({type: actionTypes.SetUser, payload: { user: response.data }});

      responsesucceedHandler(response);

    } catch (error) {

      responseErrorHandler(error.response);
      
    }

  },
  changePassword: (form, responsesucceedHandler, responseErrorHandler, id) => async (dispatch, getState) => {

    try {
      const response = id ? await axios.post(`/api/users/${id}password_secondary_account/`, form) : await axios.post(`/api/users/password/`, form)

      responsesucceedHandler(response);
      
    } catch (error) {

      responseErrorHandler(error.response);
      
    }

  },
  createSecondaryAccount: (form, responsesucceedHandler, responseErrorHandler) => async (dispatch, getState) => {

    try {

      let response = await axios.post('/api/users/', form);
      responsesucceedHandler(response);
      
    } catch (error) {
      responseErrorHandler(error.response.data.message);
      
    }

  },

  updateSecondaryAccount: (form, responsesucceedHandler, responseErrorHandler) => async (dispatch, getState) => {

    try {

      const response = await axios.post('/api/users/edit/', form);

      responsesucceedHandler(response);
      
    } catch (error) {

      responseErrorHandler(error.response.data.message);
      
    }

  }
  
};


export function* saga() {

  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data } = yield getUserByToken();

    yield put(actions.fulfillUser(data));
  });
  
}